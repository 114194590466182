import { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getImageSize } from "react-image-size";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Item } from "../types/ItemType";
import "../static/css/pages/PaintDetail.css";
import { useShoppingCart } from "../context/useShoppingCartContext";
import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const PaintDetail = () => {
  const [activeImage, setActiveImage] = useState<any>(null);

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const navigateToTop = useNavigateToTop();
  navigateToTop();

  const { addItemToCart } = useShoppingCart();

  const navigate = useNavigate();

  const location = useLocation();
  const { artDetail } = location.state;

  const paint: Item = {
    id: artDetail.id,
    title: artDetail.title,
    main_image: artDetail.main_image,
    size: artDetail.size1,
    price: artDetail.price1,
    is_paint: artDetail.is_paint,
    status: artDetail.status,
    quantity: 1,
  };

  const [orientation, setOrientation] = useState<"Horizontal" | "Vertical">(
    "Horizontal"
  );

  const getImageOrientation = async () => {
    const dimensions = await getImageSize(artDetail.main_image);

    if (dimensions?.height && dimensions.width) {
      if (dimensions.height >= dimensions.width) {
        setOrientation("Vertical");
      } else if (dimensions.height < dimensions.width) {
        setOrientation("Horizontal");
      }
    }
  };

  useEffect(() => {
    getImageOrientation();
  }, []);

  useEffect(() => {
    dialogRef.current?.addEventListener("close", closeModal);
    if (activeImage === null) return;
    else {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    }
  }, [activeImage]);

  const closeModal = () => {
    dialogRef.current?.close();
    document.body.style.overflow = "";
    setActiveImage(null);
  };

  return (
    <div className="paint-detail-container">
      <dialog
        ref={dialogRef}
        style={{
          border: "none",
          borderRadius: 5,
          background: "gray",
          position: "relative",
        }}
      >
        <img
          src={activeImage}
          style={{
            maxHeight: "90vh",
            maxWidth: "90vw",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            backgroundColor: "var(--brand-color)",
            width: 30,
            height: 30,
            borderRadius: 15,
            display: "grid",
            placeItems: "center",
          }}
          onClick={closeModal}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "white", fontSize: 18 }}
          />
        </div>
      </dialog>
      <div className="main-image-container">
        <img
          className="art"
          onClick={() => setActiveImage(artDetail.main_image)}
          src={artDetail.main_image}
          style={
            orientation === "Horizontal"
              ? { width: "min(600px, 97%)", objectFit: "cover" }
              : { width: "min(400px, 97%)", objectFit: "cover" }
          }
        />
      </div>
      <div>
        <h1 style={{ color: "var(--brand-color)" }}>{artDetail.title}</h1>
        <h3 style={{ color: "var(--brand-color)", fontSize: 24 }}>Story</h3>
        <p> {artDetail.description}</p>
        <div>
          <p style={{ fontSize: 18 }}>
            <b>Size:</b> {artDetail.size1}
          </p>
          {artDetail.status === "Not Sold" ? (
            <p style={{ fontSize: 18, color: "green" }}>
              Price: ${artDetail.price1.toLocaleString()}
            </p>
          ) : artDetail.status === "In Process" ? (
            <p>
              <b style={{ fontSize: 18, color: "#e8b117" }}>In Process</b>
            </p>
          ) : (
            <p>
              <b style={{ fontSize: 18, color: "red" }}>SOLD</b>
            </p>
          )}
        </div>
        <div>
          <button
            className="buy-btn"
            onClick={() => {
              // toast.error(
              //   "Regrettably, due to unforeseen circumstances, art purchases are temporarily unavailable at this time",
              //   { duration: 6000, position: "bottom-center" }
              // );
              // return;
              const status: any = addItemToCart(paint);
              if (status.status === "success") {
                navigate("/cart");
              }
            }}
          >
            Buy
          </button>
          <button
            className="add-to-cart-btn"
            onClick={() => {
              // toast.error(
              //   "Regrettably, due to unforeseen circumstances, art purchases are temporarily unavailable at this time",
              //   { duration: 6000, position: "bottom-center" }
              // );
              // return;
              addItemToCart(paint);
            }}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  );
};
