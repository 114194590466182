import "../static/css/components/DisplayEvent.css";
import { Nav } from "./Nav";

export const DisplayEvent = () => {
  return (
    <div className="dispaly-event-container">
      <div
        style={{
          position: "relative",
        }}
      >
        <Nav />
        <div>
          <img
            src={require("../static/images/display-event-img.jpg")}
            className="display-event-img"
          />
        </div>
        {/* <div className="moto-container">
          <div className="moto">
            <p>***</p>
            <p className="moto-text" style={{ fontSize: 18 }}>
              Tsegalidet Tefera - Artist/Painter <br />
              <br /> Inspiration can come from anywhere, and sometimes the
              sources are surprising. The key is in how you see.
            </p>
            <p>***</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
