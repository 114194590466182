import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Spinner = () => {
  return (
    <FontAwesomeIcon
      color="dark"
      style={{ fontSize: "30px" }}
      icon={faSpinner}
      spinPulse
    />
  );
};
