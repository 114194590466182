import { useNavigate } from "react-router-dom";

// Create a custom hook to navigate and reset scroll position
export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateToTop = () => {
    // navigate(to, { replace: true });
    window.scrollTo(0, 0);
  };

  return navigateToTop;
};
