import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Art } from "../types/ArtType";
import { URL } from "../utilities/CONSTANTS";

type useArtDataProps = {
  queryKey: string[];
  filter: (data: any) => Art[];
};

export const useArtData = ({ queryKey, filter }: useArtDataProps) => {
  const fetchArt = () => {
    return axios.get(`${URL}/arts_api/`);
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: fetchArt,
    select: (data) => filter(data),
  });
};
