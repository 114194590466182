import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { URL } from "../utilities/CONSTANTS";
import { getCookie } from "../utilities/Functions";

const csrftoken = getCookie("csrftoken");

export const useUpdateStatus = () => {
  const updateArtStatus = async ({
    artId,
    status,
  }: {
    artId: string;
    status: string;
  }) => {
    const data = JSON.stringify({
      status: status,
    });
    return await axios.put(`${URL}/arts_api/${artId}/`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  };

  return useMutation({
    mutationFn: updateArtStatus,
    mutationKey: ["arts"],
  });
};
