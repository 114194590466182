import { ArtistDescription } from "../components/ArtistDescription";
import { Nav } from "../components/Nav";
import { useNavigateToTop } from "../hooks/useNavigationToTop";
import "../static/css/components/AboutDescription.css";

export const AboutArtist = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div className="about-artist">
      <Nav />
      <ArtistDescription />
    </div>
  );
};
