import { Link } from "react-router-dom";

import { Art } from "../types/ArtType";
import "../static/css/components/Card.css";

type CardProps = {
  pathname: string;
  art: Art;
};

export const Card = ({ pathname, art }: CardProps) => {
  const maxLength: number = 15;

  const truncatedTitle =
    art.title.length < maxLength
      ? art.title.slice(0, maxLength)
      : art.title.slice(0, maxLength - 3) + "...";

  return (
    <div className="card-container">
      <Link
        to={{ pathname: pathname }}
        state={{ artDetail: art }}
        style={{ textDecoration: "none" }}
      >
        <div className="card" style={{ width: 260 }}>
          <img src={art.main_image} className="img" />
          <div className="body">
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p className="size">{truncatedTitle}</p>
              {/* <p className="size">{art.description}</p> */}
              {art.status === "Sold" && art.is_paint ? (
                <div
                  style={{
                    borderRadius: 15,
                    width: 30,
                    height: 30,
                    backgroundColor: "#e84a5f",
                    // width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontSize: 9, color: "white", fontWeight: "900" }}>
                    SOLD
                  </p>
                </div>
              ) : (
                <p className="price">${art.price1.toLocaleString()}</p>
              )}
            </div>
            {/* {art.is_paint ? (
              <div>
                {art.status === "Not Sold" ? (
                  <div className="size">{truncatedTitle}</div>
                ) : (
                  <b>
                    <p
                      style={{
                        backgroundColor: "rgb(54, 53, 53)",
                        color: "white",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      {art.status}
                    </p>
                  </b>
                )}
              </div>
            ) : null} */}
          </div>
        </div>
      </Link>
    </div>
  );
};
