import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const PrivacyPolicy = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", justifyContent: "center" }}
    >
      <h1>Privacy Policy</h1>
    </div>
  );
};
