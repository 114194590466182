import { NavLink } from "react-router-dom";
import "../static/css/components/NavigationBar.css";

export const Nav = () => {
  return (
    <div className="nav-container">
      <div className="nav">
        <NavLink to={"/"} className="nav-bar-link">
          Home
        </NavLink>
        <NavLink to={"/paint"} className="nav-bar-link">
          Original Paint
        </NavLink>
        <NavLink to={"/print"} className="nav-bar-link">
          Canvas Print
        </NavLink>
        <NavLink to={"/events"} className="nav-bar-link">
          Events
        </NavLink>
        <NavLink to={"/contact_artist"} className="nav-bar-link">
          Contact
        </NavLink>
        <NavLink to={"/about_artist"} className="nav-bar-link">
          About
        </NavLink>
      </div>
    </div>
  );
};
