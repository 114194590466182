import "../static/css/components/Body.css";

export const Body = () => {
  return (
    <div className="body-container">
      <div className="about-artist-body">
        <div className="col">
          <img
            src={require("../static/images/pan-africanism.jpg")}
            className="artist-img"
          />
        </div>
        <div className="col">
          <div className="biography">
            <h1 style={{ color: "var(--brand-color)" }}>
              Tsegalidet’s Art about Pan Africanism
            </h1>
            <p style={{ fontSize: 20 }}>
              His Art shows how he see the formation of Africa in Pan African
              eye. Those colors reflect the deepest strong bonds of solidarity
              between Africa. That is a True Art from the heart. I see a great
              battle in his mind while choosing those colors. His arts are rich
              by strong colors of African identity with the true character,
              whereby colors are used to suggest emotional process. There are
              various interpretations of pan Africanism and one is that
              Tsegalidet’s art depicts the end of African formation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
