import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";

import { Spinner } from "./Spinner";
import "../static/css/components/PayPal.css";
import { useShoppingCart } from "../context/useShoppingCartContext";
import { Item } from "../types/ItemType";
import { useUpdateStatus } from "../hooks/useUpdateStatus";

export const PayPal = ({ amount }: { amount?: string }) => {
  const { cart } = useShoppingCart();
  const { mutate } = useUpdateStatus();
  const [{ isPending }] = usePayPalScriptReducer();

  const { clearCart } = useShoppingCart();

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: amount,
          },
        },
      ],
    });
  };

  const handleCancle = () => {
    toast.error("You cancelled the payment.", {
      duration: 6000,
    });
  };

  const handleError = () => {
    toast.error("Something went wrong", {
      duration: 6000,
    });
  };

  const handleApprove = async (data: any, actions: any) => {
    const params = {
      name: "tsega",
      email: "tsegalidetpaintings9@gmail.com",
      subject: "PayPal",
      message: JSON.stringify(cart),
    };

    try {
      await emailjs.send(
        "service_so68jo8",
        "template_p11y5mi",
        {
          to_name: "", // enter the user's name
          from_name: "tsegalidet",
          subject: "PayPal Order",
          message: JSON.stringify(cart),
        },
        "8ACdJuQegy0GhYo8N"
      );
      clearCart("Sold");
      return actions.order.capture().then(function (details: any) {
        toast.success(
          "Payment completed. Thank you, " + details.payer.name.given_name,
          {
            duration: 6000,
          }
        );
      });
    } catch {
      toast.error("Something went wrong", {
        duration: 6000,
      });
    }
  };

  return (
    <div className="paypal-container">
      {isPending ? (
        <Spinner />
      ) : (
        <PayPalButtons
          style={{ layout: "vertical", height: 55 }}
          createOrder={createOrder}
          onCancel={handleCancle}
          onError={handleError}
          onApprove={handleApprove}
        />
      )}
    </div>
  );
};
