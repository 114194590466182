import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../static/css/components/ScrollToTop.css";

export const ScrollToTop = () => {
  const { innerHeight: height, innerWidth: width } = window;
  const [displayButton, setDisplayButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1500) setDisplayButton(true);
      else setDisplayButton(false);
    });
  }, []);

  return (
    <div
      id="scroll-to-top"
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      style={{
        bottom: 0.1 * height,
        right: 0.05 * width,
        display: displayButton ? "flex" : "none",
      }}
    >
      <FontAwesomeIcon
        icon={faChevronUp}
        className="icons"
        style={{
          color: "white",
        }}
      />
    </div>
  );
};
