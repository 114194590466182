import { useShoppingCart } from "../context/useShoppingCartContext";
import { Item } from "../types/ItemType";

export const CartItem = (item: Item) => {
  const { increaseItemQuantity, decreaseItemQuantity } = useShoppingCart();
  return (
    <div
      style={{
        display: "flex",
        width: "min(900px, 85%)",
        justifyContent: "space-around",
        alignItems: "center",
        margin: 10,
      }}
    >
      <img
        src={item.main_image}
        style={{ width: "200px", objectFit: "cover" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>
          <b>Price:</b> ${item.price.toLocaleString()}
        </span>
        <span>
          <b>Size:</b> {item.size}
        </span>
        <div
          style={{
            display: "flex",
            height: 20,
            width: 70,
            alignItems: "center",
            justifyContent: "space-between",
            margin: 10,
          }}
        >
          <button onClick={() => increaseItemQuantity(item.id, item.size)}>
            +
          </button>
          <p>{item.quantity}</p>
          <button onClick={() => decreaseItemQuantity(item.id, item.size)}>
            -
          </button>
        </div>
      </div>
    </div>
  );
};
