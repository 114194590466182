import { Outlet } from "react-router-dom";
import { Navigation } from "./Navigation";
import { Footer } from "./Footer";
import { NavigationBar } from "./NavigationBar";
import { ScrollToTop } from "./ScrollToTop";

export const Main = () => {
  return (
    <>
      <ScrollToTop />
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};
