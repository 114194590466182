import "../static/css/components/NavigationBar.css";
import {
  faArrowLeft,
  faBars,
  faSearch,
  faShoppingBag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

type NavigationBarProps = {
  isMenuBtnClicked: boolean;
  setMenuBtnClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavigationBar = ({
  isMenuBtnClicked,
  setMenuBtnClicked,
}: NavigationBarProps) => {
  const [searchBtnClicked, setSearchBtnClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div id="nav-bar" style={{ justifyContent: "space-between" }}>
      {location.pathname === "/paint_detail" ||
      location.pathname === "/print_detail" ? (
        <div className="left-nav-icons">
          <FontAwesomeIcon
            onClick={() => {
              navigate(-1);
            }}
            icon={faArrowLeft}
            className="icons"
            style={{
              fontSize: "18px",
            }}
          />
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            navigate("/search", {
              state: { searchQuery: searchQuery },
            });
          }}
          className="search"
          id="search-bar"
          style={{
            color: "white",
            width: searchBtnClicked ? "min(100%, 400px)" : 20,
            marginRight: searchBtnClicked ? 20 : 0,
          }}
        >
          <input
            placeholder="Search"
            type="search"
            className="search__input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ opacity: searchBtnClicked ? 1 : 0 }}
          />
          <div className="search__button" id="search-button">
            <FontAwesomeIcon
              icon={faSearch}
              className="search_icon"
              style={{ display: searchBtnClicked ? "none" : "block" }}
              onClick={() => setSearchBtnClicked(true)}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="close_icon"
              style={{ display: !searchBtnClicked ? "none" : "block" }}
              onClick={() => setSearchBtnClicked(false)}
            />
          </div>
        </form>
      )}
      <div
        className="nav-logo"
        style={{
          display:
            searchBtnClicked &&
            location.pathname !== "/print_detail" &&
            searchBtnClicked &&
            location.pathname !== "/paint_detail"
              ? "none"
              : "block",
        }}
      >
        <Link to={""}>
          <img id="logo" src={require("../static/images/logo.png")} />
        </Link>
      </div>
      <div
        className="right-nav-icons"
        style={{
          display:
            searchBtnClicked &&
            location.pathname !== "/print_detail" &&
            searchBtnClicked &&
            location.pathname !== "/paint_detail"
              ? "none"
              : "flex",
        }}
      >
        <div className="nav-icons">
          <NavLink to={"/cart"} className="nav-bar-link cart-link">
            <FontAwesomeIcon
              icon={faShoppingBag}
              className="icons"
              style={{ fontSize: "18px" }}
            />
          </NavLink>
        </div>
        {!isMenuBtnClicked ? (
          <div
            className="nav-icons icon-bar"
            onClick={() => setMenuBtnClicked(true)}
          >
            <FontAwesomeIcon
              icon={faBars}
              style={{ fontSize: "18px" }}
              className="icons"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
