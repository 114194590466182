import { Link } from "react-router-dom";

import { Card } from "./Card";
import { ThemeCarousel } from "./ThemeCarousel";
import { Art } from "../types/ArtType";
import { Spinner } from "./Spinner";
import "../static/css/components/Theme.css";

import { useArtData } from "../hooks/useArtData";

export const Theme = () => {
  const filter = (data: any) => {
    let filteredArt: Art[] = [];

    data.data.map((art: Art) => {
      if (art.display_on_homepage) filteredArt.push(art);
    });

    return filteredArt;
  };

  const queryKey = ["arts", "theme"];
  const { isLoading, isError, data, error } = useArtData({ queryKey, filter });

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (isError) {
    console.log(error.message);
    return (
      <div
        style={{
          display: "flex",
          minHeight: "50vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Somthing went wrong</h3>
      </div>
    );
  }

  return (
    <div className="theme">
      <div style={{ justifyContent: "center", height: "15vh", width: "100%" }}>
        <h1 className="print-header">Canvas Print</h1>
      </div>
      <ThemeCarousel>
        {data?.map((art: Art) => {
          return art ? (
            <div key={art.id} style={{ margin: 30 }}>
              <Card pathname="/print_detail" art={art} />
            </div>
          ) : null;
        })}
      </ThemeCarousel>
      <Link to={"/print"} style={{ marginTop: 20, marginBottom: 40 }}>
        <button className="add-to-cart-btn">See All</button>
      </Link>
    </div>
  );
};
