import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PrintImageCarousel } from "../components/PrintImageCarousel";
import { Item } from "../types/ItemType";
import "../static/css/pages/PrintDetail.css";
import { useShoppingCart } from "../context/useShoppingCartContext";
import toast from "react-hot-toast";
import { useNavigateToTop } from "../hooks/useNavigationToTop";
import { getImageSize } from "react-image-size";

export const PrintDetail = () => {
  const navigateToTop = useNavigateToTop();

  const [activeImage, setActiveImage] = useState<any>(null);

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const navigate = useNavigate();

  const { addItemToCart } = useShoppingCart();
  const location = useLocation();
  const { artDetail } = location.state;

  const [size, setSize] = useState(artDetail.size1);
  const [price, setPrice] = useState(artDetail.price1);

  const print: Item = {
    id: artDetail.id,
    title: artDetail.title,
    main_image: artDetail.main_image,
    size: size,
    price: price,
    is_paint: artDetail.is_paint,
    status: artDetail.status,
    quantity: 1,
  };

  const changeSizeAndPrice = (size: string) => {
    switch (size) {
      case "size1":
        setSize(artDetail.size1);
        setPrice(artDetail.price1);

        return;
      case "size2":
        setSize(artDetail.size2);
        setPrice(artDetail.price2);

        return;
      case "size3":
        setSize(artDetail.size3);
        setPrice(artDetail.price3);

        return;
      case "size4":
        setSize(artDetail.size4);
        setPrice(artDetail.price4);

        return;
      case "size5":
        setSize(artDetail.size5);
        setPrice(artDetail.price5);

        return;
      case "size6":
        setSize(artDetail.size6);
        setPrice(artDetail.price6);

        return;
      default:
        return;
    }
  };

  const [orientation, setOrientation] = useState<"Horizontal" | "Vertical">(
    "Horizontal"
  );

  useEffect(() => {
    dialogRef.current?.addEventListener("close", closeModal);
    if (activeImage === null) return;
    else {
      navigateToTop();
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    }
  }, [activeImage]);

  const closeModal = () => {
    dialogRef.current?.close();
    document.body.style.overflow = "";
    setActiveImage(null);
  };

  const getImageOrientation = async () => {
    const dimensions = await getImageSize(artDetail.main_image);

    if (dimensions?.height && dimensions.width) {
      if (dimensions.height >= dimensions.width) {
        setOrientation("Vertical");
      } else if (dimensions.height < dimensions.width) {
        setOrientation("Horizontal");
      }
    }
  };

  useEffect(() => {
    getImageOrientation();
    navigateToTop();
  }, []);

  return (
    <div className="print-detail-container">
      <dialog
        ref={dialogRef}
        style={{
          border: "none",
          borderRadius: 5,
          background: "gray",
          position: "relative",
        }}
      >
        <img
          src={activeImage}
          style={{ maxHeight: "90vh", maxWidth: "80vw" }}
        />
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            backgroundColor: "var(--brand-color)",
            width: 30,
            height: 30,
            borderRadius: 15,
            display: "grid",
            placeItems: "center",
          }}
          onClick={closeModal}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "white", fontSize: 18 }}
          />
        </div>
      </dialog>
      <div className="print-carousel-container">
        <div
          style={
            orientation === "Horizontal"
              ? { width: "min(600px, 97%)", objectFit: "cover" }
              : { width: "min(400px, 97%)", objectFit: "cover" }
          }
        >
          <PrintImageCarousel>
            <img
              className="art"
              src={artDetail.main_image}
              onClick={() => setActiveImage(artDetail.main_image)}
            />
            {artDetail.image1 ? (
              <img
                className="art"
                src={artDetail.image1}
                onClick={() => setActiveImage(artDetail.image1)}
              />
            ) : null}
            {artDetail.image2 ? (
              <img
                className="art"
                src={artDetail.image2}
                onClick={() => setActiveImage(artDetail.image2)}
              />
            ) : null}
            {artDetail.image3 ? (
              <img
                className="art"
                src={artDetail.image3}
                onClick={() => setActiveImage(artDetail.image3)}
              />
            ) : null}
            {artDetail.image4 ? (
              <img
                className="art"
                src={artDetail.image4}
                onClick={() => setActiveImage(artDetail.image4)}
              />
            ) : null}
            {artDetail.image5 ? (
              <img
                className="art"
                src={artDetail.image5}
                onClick={() => setActiveImage(artDetail.image5)}
              />
            ) : null}
            {artDetail.image6 ? (
              <img
                className="art"
                src={artDetail.image6}
                onClick={() => setActiveImage(artDetail.image6)}
              />
            ) : null}
            {artDetail.image7 ? (
              <img
                className="art"
                src={artDetail.image7}
                onClick={() => setActiveImage(artDetail.image7)}
              />
            ) : null}
            {artDetail.image8 ? (
              <img
                className="art"
                src={artDetail.image8}
                onClick={() => setActiveImage(artDetail.image8)}
              />
            ) : null}
            {artDetail.image9 ? (
              <img
                className="art"
                src={artDetail.image9}
                onClick={() => setActiveImage(artDetail.image9)}
              />
            ) : null}
            {artDetail.image10 ? (
              <img
                className="art"
                src={artDetail.image10}
                onClick={() => setActiveImage(artDetail.image10)}
              />
            ) : null}
          </PrintImageCarousel>
        </div>
      </div>
      <div>
        <h1 style={{ color: "var(--brand-color)" }}>{artDetail.title}</h1>
        {/* <p style={{ fontSize: 20 }}>Select Size: </p> */}
        <div
          style={{
            display: "flex",
            width: "max(300px, 20vw)",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 130 }}>
            <p style={{ fontSize: 20 }}>Canvas Print</p>
            <select
              className="selector"
              onChange={(e) => changeSizeAndPrice(e.target.value)}
            >
              <option value={"size1"}>{artDetail.size1}</option>
              {artDetail.size2 ? (
                <option value={"size2"}>{artDetail.size2}</option>
              ) : null}
              {artDetail.size3 ? (
                <option value={"size3"}>{artDetail.size3}</option>
              ) : null}
            </select>
          </div>
          {artDetail.size4 ? (
            <div style={{ width: 130 }}>
              <p style={{ fontSize: 20 }}>Fine Art Print</p>
              <select
                className="selector"
                onChange={(e) => changeSizeAndPrice(e.target.value)}
              >
                <option value={""}>Select</option>
                <option value={"size4"}>{artDetail.size4}</option>
                {artDetail.size5 ? (
                  <option value={"size5"}>{artDetail.size5}</option>
                ) : null}
                {artDetail.size6 ? (
                  <option value={"size6"}>{artDetail.size6}</option>
                ) : null}
              </select>
            </div>
          ) : null}
        </div>
        <p style={{ fontSize: 20 }}>Price: ${price.toLocaleString()}</p>
        <p style={{ fontSize: 20 }}>
          Print Type:{" "}
          {size === artDetail?.size1 ||
          size === artDetail?.size2 ||
          size === artDetail?.size3
            ? "Canvas Print"
            : "Fine Art Print"}
        </p>
        <div>
          <button
            className="buy-btn"
            onClick={() => {
              // toast.error(
              //   "Regrettably, due to unforeseen circumstances, art purchases are temporarily unavailable at this time",
              //   { duration: 6000, position: "bottom-center" }
              // );
              // return;
              addItemToCart(print);
              navigate("/cart");
            }}
          >
            Buy
          </button>
          <button
            className="add-to-cart-btn"
            onClick={() => {
              // toast.error(
              //   "Regrettably, due to unforeseen circumstances, art purchases are temporarily unavailable at this time",
              //   { duration: 6000, position: "bottom-center" }
              // );
              // return;
              addItemToCart(print);
            }}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  );
};
