export const ArtistDescription = () => {
  return (
    <div className="about-artist-container">
      <div className="about-artist-body">
        <div className="col">
          <div className="biography">
            <h1 style={{ color: "var(--brand-color)" }}>About Artist</h1>
            <p style={{ fontSize: 20 }}>
              Tsegalidet Tefera was born in Addis Ababa, Ethiopia. After earning
              his certificate from Russian Cultural center institution in Addis
              Ababa Ethiopia. He decided to further his painting by pursuing to
              become a full-time studio artist. In his earlier works, Tsegaledet
              has explored the beauty of Ethiopian traditional ornamental
              painting from authentic and original Ethiopian folk
              art/traditional painting he develops his own autunitic and
              beautiful style in his own terms Tsegaledet Tefera exhibits
              nationally in Art of Ethiopia (AOE) and shows different art shows
              in Addis Ababa and continues to contribute to the developing art
              culture in Ethiopia through Ethiopian Visual art and culture.
            </p>
          </div>
          <div className="work-description">
            <h1 style={{ color: "var(--brand-color)" }}>Work Description</h1>
            <p style={{ fontSize: 20 }}>
              His subject maters are in search of beauty and aesthetics inspired
              from the painting style of old Ethiopian byzantine style of church
              painting. he harmonizes with the contemporary fashionable style in
              a beautiful and vibrant color and amazing composition for
              Ethiopian colorful representation his colorful composition and
              authentic style makes him to recognized among today's Ethiopian
              contemporary artist his art works is displayed in different
              galleries, museums and commend in different hotels and cultural
              institution.
            </p>
          </div>
        </div>
        <div className="col">
          <img
            src={require("../static/images/about-artist-img.jpg")}
            className="artist-img"
          />
        </div>
      </div>
    </div>
  );
};
