import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

type PrintImageCarouselProps = {
  children: React.ReactNode;
};

export const PrintImageCarousel = ({ children }: PrintImageCarouselProps) => {
  const carouselSettings = {
    items: 1,
    loop: false,
    nav: true,
    dots: true,
  };
  return (
    <>
      <OwlCarousel className="owl-theme" {...carouselSettings}>
        {children}
      </OwlCarousel>
    </>
  );
};
