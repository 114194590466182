import { ContactArtistForm } from "../components/ContactArtistForm";
import { Nav } from "../components/Nav";
import { useNavigateToTop } from "../hooks/useNavigationToTop";
import "../static/css/pages/ContactArtist.css";

export const ContactArtist = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div>
      <Nav />
      <div className="contact-artist-container">
        <h1 style={{ color: "var(--brand-color)" }}>Contact</h1>
        <ContactArtistForm />
      </div>
    </div>
  );
};
