import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLockBodyScroll } from "@uidotdev/usehooks";

type FullScreenNavBarProps = {
  setMenuBtnClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FullScreenNavBar = ({
  setMenuBtnClicked,
}: FullScreenNavBarProps) => {
  useLockBodyScroll();
  return (
    <div id="menu-bar">
      <button className="close-btn" onClick={() => setMenuBtnClicked(false)}>
        <FontAwesomeIcon
          style={{ fontSize: "25px", color: "white" }}
          icon={faXmark}
        />
      </button>
      <div className="menu-bar-nav">
        <NavLink
          to={""}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          Home
        </NavLink>
        <NavLink
          to={"paint"}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          Original Paint
        </NavLink>
        <NavLink
          to={"print"}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          Canvas Print
        </NavLink>
        <NavLink
          to={"events"}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          Events
        </NavLink>
        <NavLink
          to={"contact_artist"}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          Contact
        </NavLink>
        <NavLink
          to={"about_artist"}
          onClick={() => setMenuBtnClicked(false)}
          className="menu-bar-link"
        >
          About
        </NavLink>
      </div>
    </div>
  );
};
