import { useContext, createContext, ReactNode } from "react";
import { Item } from "../types/ItemType";
import { useLocalStorage } from "./useLocalStorage";
import toast from "react-hot-toast";
import { useUpdateStatus } from "../hooks/useUpdateStatus";

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type ShoppingCartContextType = {
  cart: Item[];
  addItemToCart: (item: Item) => void;
  increaseItemQuantity: (itemId: string, itemSize: string) => void;
  decreaseItemQuantity: (itemId: string, itemSize: string) => void;
  removeItemFromCart: (itemId: string) => void;
  clearCart: (status: string) => void;
};

const ShoppingCartContext = createContext({} as ShoppingCartContextType);

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};

export const ShoppingCartProvider = ({
  children,
}: ShoppingCartProviderProps) => {
  const { mutate } = useUpdateStatus();
  const [cart, setCart] = useLocalStorage<Item[]>("ShoppingCart", []);

  const addItemToCart = (item: Item) => {
    if (item.is_paint && item.status !== "Not Sold") {
      toast.error(
        "This art doesn't have a status of NOT SOLD, so you can not buy it",
        {
          duration: 6000,
          position: "bottom-center",
        }
      );

      return { status: "failed" };
    }
    if (cart.find((i) => i.id === item.id && i.size === item.size)) {
      toast.error("You've already added this item\nnavigate to Cart to see", {
        duration: 6000,
        position: "bottom-center",
      });
      return { status: "failed" };
    }

    if (item.is_paint) {
      mutate({
        artId: item.id,
        status: "In Process",
      });
    }

    setCart((prevCart) => [...prevCart, item]);
    toast.success("Successfully added to cart", {
      duration: 6000,
      position: "bottom-center",
    });

    return { status: "success" };
  };

  const increaseItemQuantity = (itemId: string, itemSize: string) => {
    cart.map((item) => {
      if (item.id === itemId && item.size === itemSize) {
        if (item.is_paint) {
          toast.error("you can not add more than 1 for paint", {
            duration: 6000,
            position: "bottom-center",
          });
          return;
        }
        return (item.quantity = item.quantity + 1);
      }
    });

    setCart([...cart]);
  };

  const decreaseItemQuantity = (itemId: string, itemSize: string) => {
    cart.map((item) => {
      if (item.id === itemId && item.size === itemSize) {
        item.quantity = item.quantity - 1;
        setCart([...cart]);
        if (item.quantity <= 0) {
          setCart(
            cart.filter((i) => {
              return !(i.id === itemId && i.size === itemSize);
            })
          );
          if (item.is_paint) {
            mutate({
              artId: item.id,
              status: "Not Sold",
            });
          }
        }
      }
    });
  };

  const removeItemFromCart = (itemId: string) => {
    cart.filter((item) => item.id !== itemId);
    cart.map((item: Item) => {
      if (item.id === itemId && item.is_paint) {
        mutate({
          artId: itemId,
          status: "Not Sold",
        });
      }
    });
  };

  const clearCart = (status: string) => {
    if (cart.length !== 0) {
      cart.map((item: Item) => {
        if (item.is_paint) {
          mutate({
            artId: item.id,
            status: status,
          });
        }
      });
      setCart([]);
    }
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cart,
        addItemToCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        removeItemFromCart,
        clearCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};
