import "../static/css/components/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTelegram,
  faFacebook,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer id="contact">
      <div className="social-media">
        <a
          href="https://www.instagram.com/tsegalidet_paintings/?utm_source=qr&igsh=MXVzaDFyM25xZHlxMQ%3D%3D"
          className="social-media-links"
          target="_blank"
        >
          <FontAwesomeIcon color="white" icon={faInstagram} />
        </a>
        <a
          href="https://www.tiktok.com/@tsegalidet_paintings?_t=8lUUCnrDzYD&_r=1"
          className="social-media-links"
          target="_blank"
        >
          <FontAwesomeIcon color="white" icon={faTiktok} />
        </a>
        <a
          href="https://t.me/tsegalidetpaintings"
          className="social-media-links"
          target="_blank"
        >
          <FontAwesomeIcon color="white" icon={faTelegram} />
        </a>
        <a
          href="https://www.facebook.com/EthioArtistPainter"
          className="social-media-links"
          target="_blank"
        >
          <FontAwesomeIcon color="white" icon={faFacebook} />
        </a>
      </div>
      <div className="privacy-policy">
        <Link
          to={
            "https://www.termsfeed.com/live/b451a509-03f0-49c3-8e20-1c95fee13f97"
          }
          style={{
            fontSize: 13,
            color: "Lightgrey",
            margin: 5,
          }}
        >
          Privacy Policy
        </Link>
      </div>
      <div className="copy-right">
        <p style={{ fontSize: 13 }}>© 2024 Tsegalidet Paintings</p>
      </div>
    </footer>
  );
};
