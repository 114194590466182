import { useState } from "react";

import { FullScreenNavBar } from "./MenuBar";
import "../static/css/components/Navigation.css";
import { NavigationBar } from "./NavigationBar";

export const Navigation = () => {
  let [isMenuBtnClicked, setMenuBtnClicked] = useState(false); // to toggle the full screen nav bar

  return (
    <>
      <NavigationBar
        isMenuBtnClicked={isMenuBtnClicked}
        setMenuBtnClicked={setMenuBtnClicked}
      />
      {isMenuBtnClicked ? (
        <FullScreenNavBar setMenuBtnClicked={setMenuBtnClicked} />
      ) : null}
    </>
  );
};
