import { Body } from "../components/Body";
import { DisplayEvent } from "../components/DisplayEvent";
import { Theme } from "../components/Theme";
import { useNavigateToTop } from "../hooks/useNavigationToTop";
import "../static/css/pages/Home.css";

export const Home = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div className="home-container">
      <DisplayEvent />
      <Theme />
      <Body />
    </div>
  );
};
