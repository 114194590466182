import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

type ThemeCarouselProps = {
  children: React.ReactNode;
};

export const ThemeCarousel = ({ children }: ThemeCarouselProps) => {
  const carouselSettings = {
    items: 2,
    loop: false,
    nav: true,
    dots: false,
    stagePadding: 50,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      920: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1550: {
        items: 5,
      },
      1900: {
        items: 6,
      },
      2250: {
        items: 7,
      },
    },
  };
  return (
    <>
      <OwlCarousel className="owl-theme" {...carouselSettings}>
        {children}
      </OwlCarousel>
    </>
  );
};
