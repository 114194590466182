import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { URL } from "../utilities/CONSTANTS";
import { getCookie } from "../utilities/Functions";

const csrftoken = getCookie("csrftoken");

export const useSubmitOrder = () => {
  const updateOrder = async ({ order }: { order: string }) => {
    const data = JSON.stringify({
      order: order,
    });
    return await axios.post(`${URL}/arts_api/order`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  };

  return useMutation({
    mutationFn: updateOrder,
    mutationKey: ["orders"],
  });
};
