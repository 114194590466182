import { Nav } from "../components/Nav";
import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const Events = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  return (
    <div>
      <Nav />
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: 20 }}>Comming Soon!</p>
      </div>
    </div>
  );
};
