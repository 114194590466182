import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Router,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Toaster } from "react-hot-toast";

import { Home } from "./pages/Home";
import { Paint } from "./pages/Paint";
import { Print } from "./pages/Print";
import { ShoppingCart } from "./pages/ShoppingCart";
import { Main } from "./components/Main";
import { PaintDetail } from "./pages/PaintDetail";
import { PrintDetail } from "./pages/PrintDetail";
import { ShoppingCartProvider } from "./context/useShoppingCartContext";
import { ContactArtist } from "./pages/ContactArtist";
import { AboutArtist } from "./pages/AboutArtiset";
import { Events } from "./pages/Events";
import { Search } from "./pages/Search";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

const queryClient = new QueryClient();

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Main />}>
        <Route index element={<Home />} />
        <Route path="search" element={<Search />} />
        {/* <Route path="theme" element={<PaintTheme />} /> */}
        <Route path="paint" element={<Paint />} />
        <Route path="paint_detail" element={<PaintDetail />} />
        <Route path="print" element={<Print />} />
        <Route path="print_detail" element={<PrintDetail />} />
        <Route path="cart" element={<ShoppingCart />} />
        <Route path="contact_artist" element={<ContactArtist />} />
        <Route path="about_artist" element={<AboutArtist />} />
        <Route path="events" element={<Events />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
      </Route>
    )
  );
  return (
    <PayPalScriptProvider
      options={{
        clientId:
          "AYKSGpdgIdGMLOOmkcRpT7SwYTjLGBOmXnt_Uc_8SzZ5ZiB1OutywPq3J6jfYIPgh72rltBG8bu3e5yS",
        currency: "USD",
        intent: "capture",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ShoppingCartProvider>
          <Toaster position="top-center" />
          <RouterProvider router={router} />
        </ShoppingCartProvider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  );
}

export default App;
