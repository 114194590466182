import { useState } from "react";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";
import { Spinner } from "./Spinner";

export const ContactArtistForm = () => {
  const [sendingMail, setSendingMail] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    setSendingMail(true);
    e.preventDefault();
    try {
      await emailjs.send(
        "service_v0sgbbl",
        "template_ayi8w03",
        {
          to_name: "Tsegalidet",
          from_name: formData.fullName,
          subject: formData.subject,
          message: formData.message,
        },
        "E7v12oPHHDLS_2cj3"
      );
      toast.success("Sent Successfully", {
        duration: 6000,
        position: "bottom-center",
      });
      setSendingMail(false);
    } catch (error) {
      toast.error("Something went wrong", {
        duration: 6000,
        position: "bottom-center",
      });
      setSendingMail(false);
    }
    setFormData({ fullName: "", email: "", subject: "", message: "" });
  };

  if (sendingMail) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div style={{ margin: 40, minWidth: 200 }}>
      <form onSubmit={handleSubmit}>
        <label style={{ fontSize: 18 }} htmlFor="fullName">
          Full Name/Brand Name:
        </label>
        <input
          required
          type="text"
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          style={{ fontSize: 18 }}
        />
        <br />

        <label style={{ fontSize: 18 }} htmlFor="email">
          Email:
        </label>
        <input
          required
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          style={{ fontSize: 18 }}
        />

        <br />

        <label style={{ fontSize: 18 }} htmlFor="subject">
          Subject:
        </label>
        <input
          required
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          style={{ fontSize: 18 }}
        />

        <br />

        <label style={{ fontSize: 18 }} htmlFor="message">
          Message:
        </label>
        <div>
          <textarea
            required
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={{
              padding: 15,
              height: 200,
              minWidth: "93%",
              fontSize: 18,
            }}
          />
        </div>

        <br />
        <button style={{ fontSize: 20 }} type="submit" className="buy-btn">
          Submit
        </button>
      </form>
    </div>
  );
};
