import { useLocation } from "react-router-dom";
import { useArtData } from "../hooks/useArtData";
import { Art } from "../types/ArtType";
import { Spinner } from "../components/Spinner";
import { Card } from "../components/Card";
import { useNavigateToTop } from "../hooks/useNavigationToTop";

export const Search = () => {
  const navigateToTop = useNavigateToTop();
  navigateToTop();

  const location = useLocation();
  const { searchQuery } = location.state;

  const filter = (data: any) => {
    let filteredArt: Art[] = [];

    data.data.map((art: Art) => {
      if (art.title.toLowerCase().includes(searchQuery.toLowerCase()))
        filteredArt.push(art);
    });

    return filteredArt;
  };

  const queryKey = ["arts", "paint"];
  const { isLoading, isError, data } = useArtData({ queryKey, filter });

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: "60vh",
          paddingTop: "175px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (searchQuery === null || isError) {
    return (
      <div
        style={{
          minHeight: "60vh",
          paddingTop: "175px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Somthing went wrong</h3>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <h1 style={{ textAlign: "center" }}>
          Search Results for "{searchQuery}"
        </h1>
        {data?.length !== 0 ? (
          <div className="art-container">
            {data?.map((art: Art, index) => {
              if (art.is_paint) {
                return (
                  <div key={index}>
                    <Card pathname="/paint_detail" art={art} />
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <Card pathname="/print_detail" art={art} />
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className="cart-container">
            <span>No Found!</span>
          </div>
        )}
      </div>
    </div>
  );
};
